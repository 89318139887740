<template>
  <div class="position-relative">
    <date-range-picker
      ref="picker"
      v-model="dateValue"
      class="form-control no-border"
      :opens="opens"
      :ranges="customRanges"
      :locale-data="localeData"
      :single-date-picker="singleDatePicker"
      :time-picker="timePicker"
      :time-picker24hour="timePicker24Hour"
      :show-week-numbers="showWeekNumbers"
      :show-dropdowns="showDropdowns"
      :linked-calendars="linkedCalendars"
      :always-show-calendars="false"
      :time-picker-increment="1"
      :auto-apply="isAutoApply"
      @update="onUpdate"
      @start-selection="onStartSelection"
      @select="onRangeSelect"
      @toggle="onToggle"
    >
      <template v-slot:input="{ startDate, endDate }">
        <div class="date-picker-input">
          {{ startDate && endDate ? formatDate(startDate) + ' to ' + formatDate(endDate) : 'Select a date range' }}
        </div>
        <div
          v-if="startDate && endDate"
          class="datepicker-cancel"
          @click="clearDate"
        >
          <feather-icon icon="XIcon" />
        </div>
      </template>
    </date-range-picker>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import moment from 'moment'

export default {
  name: 'DateRangePickerComponent',
  components: {
    DateRangePicker,
  },
  props: {
    dateValue: {
      type: Object,
      default: () => ({ startDate: null, endDate: null }),
    },
    opens: {
      type: String,
      default: 'center',
    },
    dateFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
    localeData: {
      type: Object,
      default: () => ({ firstDay: 1, format: 'yyyy-mm-dd HH:MM:ss' }),
    },
    singleDatePicker: {
      type: Boolean,
      default: false,
    },
    timePicker: {
      type: Boolean,
      default: true,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    showWeekNumbers: {
      type: Boolean,
      default: true,
    },
    showDropdowns: {
      type: Boolean,
      default: true,
    },
    linkedCalendars: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 999)

    const yesterdayStart = new Date()
    yesterdayStart.setDate(today.getDate() - 1)
    yesterdayStart.setHours(0, 0, 0, 0)

    const yesterdayEnd = new Date()
    yesterdayEnd.setDate(today.getDate() - 1)
    yesterdayEnd.setHours(23, 59, 59, 999)

    const lastWeekStart = new Date()
    lastWeekStart.setDate(yesterdayStart.getDate() - 6)
    lastWeekStart.setHours(0, 0, 0, 0)

    const lastTwoWeeksStart = new Date()
    lastTwoWeeksStart.setDate(yesterdayStart.getDate() - 13)
    lastTwoWeeksStart.setHours(0, 0, 0, 0)

    return {
      isAutoApply: true,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      customRanges: {
        Today: [today, todayEnd],
        Yesterday: [yesterdayStart, yesterdayEnd],
        'Last Week': [lastWeekStart, yesterdayEnd],
        'Last Two Weeks': [lastTwoWeeksStart, yesterdayEnd],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999),
        ],
      },
    }
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format(this.dateFormat) : ''
    },
    onUpdate(newDateRange) {
      this.$emit('update', newDateRange)
    },
    onToggle(event) {
      if (event && this.dateValue) {
        const selectedStart = new Date(this.dateValue.startDate).toISOString()
        const selectedEnd = new Date(this.dateValue.endDate).toISOString()

        // Use some() to check if any custom range matches
        const matchedRange = Object.entries(this.customRanges).find(([, dateRange]) => (
          new Date(dateRange[0]).toISOString() === selectedStart
            && new Date(dateRange[1]).toISOString() === selectedEnd
        ))

        if (matchedRange) {
          this.isAutoApply = true
        } else {
          this.isAutoApply = false
        }
      }
    },
    onStartSelection() {
      this.isAutoApply = false
    },
    onRangeSelect(range) {
      const selectedStart = new Date(range.startDate).toISOString()
      const selectedEnd = new Date(range.endDate).toISOString()

      // Use some() to check if any custom range matches
      const matchedRange = Object.entries(this.customRanges).find(([, dateRange]) => (
        new Date(dateRange[0]).toISOString() === selectedStart
          && new Date(dateRange[1]).toISOString() === selectedEnd
      ))

      if (matchedRange) {
        this.isAutoApply = true
        this.$refs.picker.clickedApply()
      }
    },
    onFinishSelection(event) {
      this.$emit('finish-selection', event)
    },
    clearDate() {
      this.$emit('update', { startDate: null, endDate: null })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../@core/scss/vue/libs/vue-daterangepicker.scss';
</style>
